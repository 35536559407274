import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function InfoPage() {

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    let token = params.token;
    let organizerId = parseInt(params.organizerId);

    window.localStorage.setItem('Token', 'Bearer ' + token);

    // navigate('/info/girlcrush');

    if (organizerId < 9) navigate('/info/legacy/' + organizerId);
    else if (organizerId === 9) navigate('/info/battlecrew3');
    else if (organizerId === 10) navigate('/info/ThePerformanceWorkshop');
    else if (organizerId === 13) navigate('/info/girlfit');
    else if (organizerId === 14) navigate('/info/girlcrush');
    else if (organizerId === 16) navigate('/info/hyrox');
    else if (organizerId === 17) navigate('/info/crossfitgamesopen');
    else if (organizerId === 18) navigate('/info/fitforceteamleague');
    else if (organizerId === 19) navigate('/info/believer');
    else if (organizerId === 20) navigate('/info/afk');
    else if (organizerId === 21) navigate('/info/linkcoach_deadlift');
    else if (organizerId === 24) navigate('/info/finalfitforceteamleague');
    else if (organizerId === 25) navigate('/info/summitcamp/');
    else if (organizerId === 26) navigate('/info/show_your_performance');
    else if (organizerId === 28) navigate('/info/2024_team_of_4');
    else if (organizerId === 29) navigate('/info/girl_crush_season2');
    else if (organizerId === 30) navigate('/info/the_mentality');
  }, [])

  return <></>;
}