import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

// import PoweredImage from '../../Image/PoweredByLink.png';
import PoweredImage from '../../Image/PoweredByLink3.png';
import FetchModule from '../../Model/Network/FetchModule';
import moment from 'moment';

export default function TheMentalityComplete2Page() {

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [data, setData] = useState({
    teamName: '',
    memberNames: '',
    members: []
  });
  const [totalVideoWidth, setTotalVideoWidth] = useState(366);
  const [totalVideoHeight, setTotalVideoHeight] = useState(456);
  const [startModifyDate, setStartModifyDate] = useState(moment());
  const [endModifyDate, setEndModifyDate] = useState(moment());
  const [isAvailableModifyAuth, setIsAvailableModifyAuth] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [handleMassage])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    // alert(e.data);
    if (responseData.goBack) navigate(-1);
  }

  // 페이지 이름 변경내역 업로드 및 받아온 데이터 정리 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: '이벤트 등록 완료', isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, [])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.884;
    let totalVideoHeight = totalVideoWidth * 1.245;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // 데이터 정리 //
  useEffect(() => {
    let token = params.token;

    if (token !== undefined) window.localStorage.setItem('Token', 'Bearer ' + token);
    // alert(token);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('event/application_state/' + 57, 'GET');
        console.log(responseData.data);
        // alert(responseData.status);
        if (responseData.status === 200) {
          let newList = JSON.parse(JSON.stringify(responseData.data.members));

          // 팀장 앞으로 빼기 //
          let tmp = undefined;
          let flag = false;

          for (let i = 0; i < newList.length; i++) {
            if (newList[i].division === 'representative') {
              tmp = newList[i];
              newList.splice(i, 1);
              newList = [tmp].concat(newList);
              break;
            }
          }
          // console.log(newList);
          // if (flag) {
          //   newList.unshift(tmp);
          // }

          responseData.data.members = newList;

          setData(responseData.data);
          setIsAvailableModifyAuth(responseData.data.isAvailableModifyAuth);
          setStartModifyDate(responseData.data.modifyStartDate);
          setEndModifyDate(responseData.data.modifyEndDate);
        }
        else if (responseData.status === 201) {
          navigate('/info/the_mentality');
        }
      }
    )();
  }, [])

  // 메인화면으로 클릭시 //
  function onClickGoHome() {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
    // if (params.isFirst === 'true') {
    //   try {
    //     window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    //   }
    //   catch (e) {
    //     console.log("Web Message Post Error : " + e);
    //   }
    // }
    // else {
    //   navigate('/check/girlcrush/' + 27 + '/false');
    // }
  }


  // 수정하기 클릭시 //
  function onClickModify() {
    // console.log(moment().isBefore(moment(startModifyDate)));
    // console.log(moment().isAfter(moment(endModifyDate)));
    if (!isAvailableModifyAuth) alert('정보수정은 팀장만 가능합니다');
    // else if (moment().isBefore(moment(startModifyDate)) || moment().isAfter(moment(endModifyDate))) alert('정보 수정 가능기간이 아닙니다');
    else if (moment().isAfter(moment(endModifyDate))) alert('정보 수정 가능기간이 아닙니다');
    else navigate('/modify/the_mentality/57');
  }


  // 참가 종목 띄우기 //
  function onCalcEventTitle(eventId) {
    return '멘탈리티 팀 : Team of 3 (남2/여1)';
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>멘탈리티 팀 : Team of 3</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>이벤트 등록 신청 완료</Text>
        </TitleWrapper>
        <ImageWrapper width={totalVideoWidth} height={totalVideoWidth} >
          <Image src='https://picture.link-zone.org/event/TheMentality/TheMentalityMain.png' />
        </ImageWrapper>
        <PoweredImageWrapper>
          <PoweredImg src={PoweredImage} />
        </PoweredImageWrapper>
        <TextComponentWrapper>
          <TextWrapper style={{ width: '100%' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{'<참가접수 이벤트>'}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'참여 인증 스토리 공유시,'}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'추첨을 통해 엔스윈드 티셔츠 10명 추첨 증정'}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{'(@nwnd_kr , @thementality_official 계정 팔로우 및 스토리 태그 필수)'}</Text>
          </TextWrapper>
          <LayerWrapper>
            <TextWrapper style={{ marginTop: '16px', width: '100%' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>종목</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{onCalcEventTitle()}</Text>
            </TextWrapper>
            {/* <TextWrapper style={{ width: '35%' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>팀이름</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{data.teamName}</Text>
            </TextWrapper> */}
          </LayerWrapper>
          <TextWrapper style={{ marginTop: '16px', width: '35%' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>팀이름</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{data.teamName}</Text>
          </TextWrapper>
          <TextWrapper style={{ marginTop: '16px', width: '100%' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>참가자</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{data.memberNames}</Text>
          </TextWrapper>
          {/* {
            data.members.length > 0 &&
            <MemberTotalInfoBox>
              <DivisionLine />
              <LayerInfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>이름</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[0].name}</Text>
                </InfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>전화번호</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[0].phone}</Text>
                </InfoBox>
              </LayerInfoBox>
              <LayerInfoBox style={{ marginTop: `24px` }}>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>성별</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[0].gender === 'male' ? '남자' : '여자'}</Text>
                </InfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>소속 체육관 이름</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[0].belong}</Text>
                </InfoBox>
              </LayerInfoBox>
              <LayerInfoBox style={{ marginTop: `24px` }}>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>소속 체육관 주소</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[0].address}</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[0].addressDetail}</Text>
                </InfoBox>
              </LayerInfoBox>
              <DivisionLine />
              <LayerInfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>이름</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[1].name}</Text>
                </InfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>전화번호</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[1].phone}</Text>
                </InfoBox>
              </LayerInfoBox>
              <LayerInfoBox style={{ marginTop: `24px` }}>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>성별</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[1].gender === 'male' ? '남자' : '여자'}</Text>
                </InfoBox>
              </LayerInfoBox>

              <DivisionLine />
              <LayerInfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>이름</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[2].name}</Text>
                </InfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>전화번호</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[2].phone}</Text>
                </InfoBox>
              </LayerInfoBox>
              <LayerInfoBox style={{ marginTop: `24px` }}>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>성별</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[2].gender === 'male' ? '남자' : '여자'}</Text>
                </InfoBox>
              </LayerInfoBox>

              <DivisionLine />
              <LayerInfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>이름</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[3].name}</Text>
                </InfoBox>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>전화번호</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[3].phone}</Text>
                </InfoBox>
              </LayerInfoBox>
              <LayerInfoBox style={{ marginTop: `24px` }}>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>성별</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.members[3].gender === 'male' ? '남자' : '여자'}</Text>
                </InfoBox>
              </LayerInfoBox>
            </MemberTotalInfoBox>
          } */}
        </TextComponentWrapper>
      </TotalWrapper>
      <MainButtonWrapper>
        {/* <MainButton onClick={onClickGoHome}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>메인 화면으로</Text>
        </MainButton> */}
        <ModifyButton onClick={onClickModify}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>정보 수정</Text>
        </ModifyButton>
      </MainButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 22px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const TextComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 88.4%;
`;

const MemberTotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 50%;
  width: 45%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 24px 0px 24px 0px;

  width: 100%;
  height: 1px;

  background-color: #E0E0E0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PoweredImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 4px;

  width: 88%;
`;

const PoweredImg = styled.img`
  width: 130px;
  height: 30px;

  // object
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const MainButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  // padding-bottom: 15px;
  margin-top: 54px;

  width: 100%;
  // height: 68px;

  background-color: #FFFFFF;
`;

const MainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #FF8B48;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;